import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsOfflineExporting from 'highcharts/modules/offline-exporting';
import HighchartsFullScreen from 'highcharts/modules/full-screen';

// Initialize all modules
HighchartsExporting(Highcharts);
HighchartsOfflineExporting(Highcharts);
HighchartsFullScreen(Highcharts);

export function createChart3(firstPortfolio, secondPortfolio) {
    var chartsElement = document.getElementById('porrfolio-graph-3');

    if (!chartsElement) {
        return;
    }

    // Function to process portfolios and handle mismatched categories
    function processPortfolios(first, second) {
        // Create sets of categories from both portfolios
        const firstCategories = new Set(first.map(item => item.name));
        const secondCategories = new Set(second.map(item => item.name));

        // Process first portfolio - no changes needed
        let processedFirst = [...first];

        // Process second portfolio
        let processedSecond = [];
        let otherTotal = 0;

        second.forEach(item => {
            if (firstCategories.has(item.name)) {
                // Category exists in first portfolio - add as is
                processedSecond.push(item);
            } else {
                // Category doesn't exist in first - add to other
                otherTotal += item.y;
            }
        });

        // Add or update "Other" category if needed
        if (otherTotal > 0) {
            const existingOtherIndex = processedSecond.findIndex(item => item.name === "Other");
            if (existingOtherIndex >= 0) {
                processedSecond[existingOtherIndex].y += otherTotal;
            } else {
                processedSecond.push({ name: "Other", y: otherTotal });
                // Add "Other" to first portfolio if it doesn't exist
                if (!firstCategories.has("Other")) {
                    processedFirst.push({ name: "Other", y: 0 });
                }
            }
        }

        // Ensure all categories from first portfolio exist in second
        firstCategories.forEach(category => {
            if (!processedSecond.some(item => item.name === category)) {
                processedSecond.push({ name: category, y: 0 });
            }
        });

        // Get final category list and sort both arrays to match
        const finalCategories = processedFirst.map(item => item.name);

        // Sort both arrays to match category order
        processedFirst.sort((a, b) => finalCategories.indexOf(a.name) - finalCategories.indexOf(b.name));
        processedSecond.sort((a, b) => finalCategories.indexOf(a.name) - finalCategories.indexOf(b.name));

        return {
            categories: finalCategories,
            firstData: processedFirst.map(item => item.y),
            secondData: processedSecond.map(item => item.y)
        };
    }

    // Process the portfolio data
    const processedData = processPortfolios(firstPortfolio, secondPortfolio);

    const chart = Highcharts.chart(chartsElement, {
        chart: {
            type: 'column',
            backgroundColor: '#fcf4e9',
            height: 310,
            width: null
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: processedData.categories
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Percentage'
            }
        },
        exporting: {
            enabled: true,  // Explicitly enable exporting
            buttons: {
                contextButton: {
                    menuItems: ["viewFullscreen", "printChart", "separator", "downloadPNG", "downloadJPEG", "downloadPDF", "downloadSVG"]
                }
            }
        },
        legend: {
            reversed: true
        },
        plotOptions: {
            series: {
                grouping: false,
                shadow: false,
                borderWidth: 0
            }
        },
        series: [{
            name: 'FISM Target Allocation',
            data: processedData.firstData,
            color: '#EA9930',
            pointPadding: 0.1,
            pointPlacement: 0
        }, {
            name: 'Your Portfolio',
            data: processedData.secondData,
            color: '#000000',
            pointPadding: 0.2,
            pointPlacement: 0
        }],
        credits: {
            enabled: false
        }
    });

    window.addEventListener('resize', function () {
        Highcharts.charts.forEach(function (chart) {
            if (chart) {
                chart.reflow();
            }
        });
    });
}