export const widgetOptions = {
    'Market Cap': [
        { label: 'Micro', value: 'MIC', class: 'fism_gs_micro-cap' },
        { label: 'Small', value: 'SC', class: 'fism_gs_small-cap' },
        { label: 'Medium', value: 'MC', class: 'fism_gs_medium-cap' },
        { label: 'Large', value: 'LC', class: 'fism_gs_large-cap' }
    ],
    'FISM Opinion': [
        { label: 'Buy', value: 'Buy', class: 'fism_gs_buy' },
        { label: 'Hold', value: 'Hold', class: 'fism_gs_hold' },
        { label: 'Sell', value: 'Sell', class: 'fism_gs_sell' },
        { label: 'Sell - Not BRI', value: 'Sell - Not BRI', class: 'fism_gs_sell-not-bri' },
        { label: 'BRI Watch', value: 'BRI Watch', class: 'fism_gs_bri-watch' }
    ],
    'Style': [
        { label: 'Income', value: 'INCOME', class: 'fism_gs_income' },
        { label: 'Growth', value: 'GROWTH', class: 'fism_gs_growth' },
        { label: 'Growth & Income', value: 'GROWTH & Income', class: 'fism_gs_growth-income' }
    ],
    'Recent': [
        { label: 'New to BUY List', value: 'New to BUY List', class: 'fism_gs_new-to-buy' },
        { label: 'OFF the Buy List', value: 'OFF the Buy List', class: 'fism_gs_off-buy-list' },
        { label: 'Recent SELLS', value: 'Recent SELLS', class: 'fism_gs_recent-sells' }
    ]
};