import { createChart } from 'lightweight-charts';
import { DateTime } from 'luxon';


export class ProcessAjax {

    constructor(Highcharts) {
        this.ajaxUrl = fism_google.ajax_url;
        this.nonce = fism_google.nonce;
        this.Highcharts = Highcharts;
    }

    async process(action, payload = null, loader = false, method = 'GET') {
        try {
            let url = this.ajaxUrl + '?action=' + action + '&nonce=' + this.nonce;


            if (loader) {
                this.createFismGsLightboxLoader();
            }

            if (payload && method === 'GET') {
                try {
                    payload = JSON.parse(payload);
                } catch (e) {
                    payload = { payload: payload };
                }

                const queryString = new URLSearchParams(payload).toString();
                url += '&' + queryString;
            }

            let options = {
                method: method,
                headers: {
                    'Content-Type': 'application/json'
                }
            };

            const response = await fetch(url, options);
            const data = await response.json();

            if (payload) {
                this.handleAction(action, data, payload);
            } else {
                this.handleAction(action, data);
            }

        } catch (error) {
            console.error('Error:', error);
        }
    }



    // Function to validate a URL
    isValidUrl(url) {
        const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
        return !!pattern.test(url);
    }



    handleAction(action, result, payload = '') {

        let metricsData, streamData;

        // Example of action-specific adjustments
        switch (action) {
            case 'importAndDownloadImport':
                alert('Spreadsheet is imported, close this message to reload the page');
                window.location.reload();
                // Additional specific handling if necessary
                console.log('Additional handling for Import.');
                break;

            case 'importAndDownloadAssets':
                alert('Spreadsheet is imported, close this message to reload the page');
                window.location.reload();
                // Additional specific handling if necessary
                console.log('Additional handling for Import.');
                break;


            case 'selectChange':


                if (result.status === 200 && result.data) {

                    var assetTitle = document.getElementById('asset-title');
                    if (result.data.title) {
                        assetTitle.textContent = result.data.title;
                        assetTitle.style.display = 'block';
                    } else {
                        assetTitle.style.display = 'none';
                    }

                    var assetDescription = document.getElementById('asset-description');
                    if (result.data.desc) {
                        assetDescription.innerHTML = result.data.desc;
                        assetDescription.style.display = 'block';
                    } else {
                        assetDescription.style.display = 'none';
                    }

                    var fismAssetsTable = document.getElementById('fism-assets-table');
                    var tableBody = fismAssetsTable.getElementsByTagName('tbody')[0];


                    tableBody.innerHTML = '';

                    var fismBuyRecommendationTitle = document.getElementById('fism-buy-recommendation-title');

                    if (result.data.recommend && result.data.recommend.length > 0) {

                        fismAssetsTable.style.display = 'table';

                        fismBuyRecommendationTitle.style.display = 'block';


                        var headerRow = document.createElement('tr');
                        headerRow.innerHTML = '<th>FISM Code</th><th>Symbol</th><th>Name</th>';
                        tableBody.appendChild(headerRow);


                        result.data.recommend.forEach(function (item) {
                            var row = document.createElement('tr');
                            row.innerHTML = '<td>' + item.fism_code + '</td><td>' + item.symbol + '</td><td>' + item.name + '</td>';
                            tableBody.appendChild(row);
                        });


                        // Prepare pie chart data
                        var pieData = result.data.piedata;

                        const chartsElement = document.querySelector('#pie-chart-container');

                        // Create Highcharts pie chart
                        var cht = this.Highcharts.chart(chartsElement, {
                            chart: {
                                type: 'pie',
                                backgroundColor: '#FFFAF3',
                            },
                            title: {
                                text: '' // Empty chart title
                            },
                            tooltip: {
                                pointFormat: '<b>{point.y}% of total</b>'
                            },
                            series: [{
                                name: 'Sectors',
                                colorByPoint: true,
                                data: pieData
                            }],
                            credits: {
                                enabled: false
                            }
                        });

                        this.createCustomLegend(cht);


                    } else {

                        fismAssetsTable.style.display = 'none';

                        fismAssetsTable.style.display = 'none';
                    }
                }



                break;

            case 'importAndDownloadMaster':
                alert('Spreadsheet is imported, close this message to reload the page');
                window.location.reload();
                // Additional specific handling if necessary
                console.log('Additional handling for Import.');
                break;

            case 'fetchGraphData':

                this.checkAndRemoveFismGsLoader();

                metricsData = this.createMetricsHTML(result.data.summary);
                streamData = result.data.stream;



                this.createPopup(metricsData, streamData, payload);

                break;

            case 'weeklyGraph':

                streamData = result.data.stream;

                this.changeGraph(streamData, payload, 'weekly');

                break;

            case 'monthlyGraph':

                streamData = result.data.stream;

                this.changeGraph(streamData, payload, 'monthly');

                break;

            case 'dailyGraph':

                streamData = result.data.stream;

                this.changeGraph(streamData, payload, 'daily');

                break;

            case 'importAndDownloadDownload':


                if (result.status === 200 && this.isValidUrl(result.data)) {

                    var link = document.createElement('a');
                    link.href = result.data;
                    link.download = 'fism_data_final.csv'; // Set the download attribute
                    // Append the link to the body
                    document.body.appendChild(link);
                    // Programmatically click the link to trigger the download
                    link.click();
                    // Remove the link from the document
                    document.body.removeChild(link);

                }

                break;
            // Add more cases as necessary
            default:
                console.log('No specific handling implemented for this action.');
        }
    }


    changeGraph(stream, payload, type) {

        const dataPoints = stream.map(entry => {
            const date = entry.date;
            const values = entry.values;
            const luxonDate = DateTime.fromISO(date); // Parse date using Luxon

            return {
                time: luxonDate.toFormat('yyyy-MM-dd'), // Format date as desired
                open: parseFloat(values["1. open"]),
                high: parseFloat(values["2. high"]),
                low: parseFloat(values["3. low"]),
                close: parseFloat(values["4. close"]),
                volume: parseFloat(values["5. volume"])
            };
        });

        dataPoints.reverse();

        // Get the canvas element
        const canvasCht = document.getElementById('fism-fonchart');

        while (canvasCht.firstChild) {
            canvasCht.removeChild(canvasCht.firstChild);
        }

        // Get the width and height of the canvas element
        const width = canvasCht.offsetWidth;
        const height = canvasCht.offsetHeight;

        // Create a new chart instance
        let chart = createChart(canvasCht, {
            width: width,
            height: height,
            timeframe: '1W',
            layout: { textColor: 'black', background: { type: 'solid', color: '#FFFAF3' } },
            localization: {
                locale: 'en-US'
            }
        });

        chart.applyOptions({
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            crosshair: {
                // hide the horizontal crosshair line
                horzLine: {
                    visible: false,
                    labelVisible: false,
                },
            },
            // hide the grid lines
            grid: {
                vertLines: {
                    visible: true,
                },
                horzLines: {
                    visible: true,
                },
            },
        });

        // Add a candlestick series to the chart
        const candlestickSeries = chart.addCandlestickSeries({
            upColor: '#1A1A1A', downColor: '#EA9A31', borderVisible: false,
            wickUpColor: '#1A1A1A', wickDownColor: '#EA9A31'

        });


        // Set the new data
        candlestickSeries.setData(dataPoints);

        const symbolName = payload.payload;

        const container = document.getElementById('fism-fonchart');

        const legend = document.createElement('div');
        legend.style = `position: absolute; left: 12px; top: 12px; z-index: 1; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 800;background: #fff;padding: 20px; border: 1px solid #000;`;
        legend.style.color = 'black';
        container.appendChild(legend);

        const getLastBar = series => {
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltipHtml = (name, date, price) => {
            legend.innerHTML = `<div style="font-size: 24px; margin: 4px 0px;">${name}</div><div style="font-size: 22px; margin: 4px 0px;">${price}</div><div>${date}</div>`;
        };

        const updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );
            const bar = validCrosshairPoint ? param.seriesData.get(candlestickSeries) : getLastBar(candlestickSeries);
            // time is in the same format that you supplied to the setData method,
            // which in this case is YYYY-MM-DD
            const time = bar.time;
            const price = bar.value !== undefined ? bar.value : bar.close;
            const formattedPrice = formatPrice(price);
            setTooltipHtml(symbolName, time, formattedPrice);
        };

        chart.subscribeCrosshairMove(updateLegend);

        updateLegend(undefined);

        // Fit the content to the new data
        chart.timeScale().fitContent();

    }

    createMetricsHTML(data) {

        if (!data) {
            return '';
        }

        const isValidValue = value => value && value !== 'None' && value !== 'NaN';

        const createDataList = (dataList) => {
            return dataList.filter(item => isValidValue(item.value)).map(item => {
                return `<li><span class="title">${item.title}</span><span class="value">${item.value}</span></li>`;
            }).join('');
        };

        const companyData = [
            { title: 'Company Name', value: data.Name },
            { title: 'Company Symbol', value: data.Symbol },
            { title: 'Industry', value: data.Industry },
            { title: 'Sector', value: data.Sector },
            { title: 'Address', value: data.Address },
            { title: 'Description', value: data.Description },
            { title: 'Fiscal Year End', value: data.FiscalYearEnd },
            { title: 'Latest Quarter', value: data.LatestQuarter }
        ];

        const priceData = [
            { title: '50 Day Moving Average', value: data['50DayMovingAverage'] },
            { title: '200 Day Moving Average', value: data['200DayMovingAverage'] },
            { title: '52 Week High', value: data['52WeekHigh'] },
            { title: '52 Week Low', value: data['52WeekLow'] },
            { title: 'Analyst Target Price', value: data['AnalystTargetPrice'] }
        ];

        const keyMetrics = [
            { title: 'Diluted EPS (TTM)', value: data['DilutedEPSTTM'] },
            { title: 'EBITDA', value: data['EBITDA'] },
            { title: 'Earnings Per Share', value: data['EPS'] },
            { title: 'EV-to-EBITDA', value: data['EVToEBITDA'] },
            { title: 'Enterprise-Value-to-Revenue', value: data['EVToRevenue'] },
            { title: 'Beta', value: data['Beta'] },
            { title: 'Forward P/E', value: data['ForwardPE'] },
            { title: 'Gross Margin (TTM)', value: data['GrossProfitTTM'] },
            { title: 'Market Capitalization', value: data['MarketCapitalization'] ? `$${parseInt(data['MarketCapitalization']).toLocaleString()}` : null },
            { title: 'Operating Margin (TTM)', value: data['OperatingMarginTTM'] ? `${(data['OperatingMarginTTM'] * 100).toFixed(2)}%` : null },
            { title: 'PEG Ratio', value: data['PEGRatio'] },
            { title: 'P/E ratio', value: data['PERatio'] },
            { title: 'Price-to-Book Ratio', value: data['PriceToBookRatio'] },
            { title: 'Price–sales ratio TTM', value: data['PriceToSalesRatioTTM'] }
        ];

        const dividendData = [
            { title: 'Dividend date', value: data['DividendDate'] },
            { title: 'Ex-dividend date', value: data['ExDividendDate'] },
            { title: 'Dividend per Share', value: data['DividendPerShare'] },
            {
                title: 'Dividend Yield',
                value: data['DividendYield'] && !isNaN(parseFloat(data['DividendYield']))
                    ? (parseFloat(data['DividendYield']) * 100).toFixed(2)
                    : ''
            }
        ];

        let htmlOutput = '<ul class="metrics-v2">';

        const companyDataHTML = createDataList(companyData);
        const priceDataHTML = createDataList(priceData);
        const keyMetricsHTML = createDataList(keyMetrics);
        const dividendDataHTML = createDataList(dividendData);

        if (companyDataHTML) {
            htmlOutput += `
        <li>
            <p>Company Data</p>
            <div id="companyData" class="dataHolder"><ul>${companyDataHTML}</ul></div>
        </li>`;
        }

        if (priceDataHTML) {
            htmlOutput += `
        <li>
            <p>Price Data</p>
            <div id="priceData" class="dataHolder"><ul>${priceDataHTML}</ul></div>
        </li>`;
        }

        if (keyMetricsHTML) {
            htmlOutput += `
        <li>
            <p>Key Metrics</p>
            <div id="keyMetrics" class="dataHolder"><ul>${keyMetricsHTML}</ul></div>
        </li>`;
        }

        if (dividendDataHTML) {
            htmlOutput += `
        <li>
            <p>Dividend Data</p>
            <div id="dividendData" class="dataHolder"><ul>${dividendDataHTML}</ul></div>
        </li>`;
        }

        htmlOutput += '</ul>';

        return htmlOutput === '<ul class="metrics-v2"></ul>' ? '' : htmlOutput;
    }

    createPopup(navHtml, streamData, payload) {
        // Check if a popup with the class 'fism-gs' already exists
        if (document.querySelector('.fism-gs')) {
            return; // If it exists, do nothing
        }

        const timeSeries = streamData["Time Series (Daily)"];

        const dataPoints = Object.keys(timeSeries).map(date => {
            const dayData = timeSeries[date];
            const luxonDate = DateTime.fromISO(date); // Parse date using Luxon
            return {
                time: luxonDate.toFormat('yyyy-MM-dd'), // Format date as desired
                open: parseFloat(dayData["1. open"]),
                high: parseFloat(dayData["2. high"]),
                low: parseFloat(dayData["3. low"]),
                close: parseFloat(dayData["4. close"])
            };
        }).reverse();

        // Create the lightbox overlay
        const lightboxOverlay = document.createElement('div');
        lightboxOverlay.className = 'lightbox-overlay';

        // Create the outer div for the popup
        const outerDiv = document.createElement('div');
        outerDiv.className = 'fism-gs';

        // Create the inner div
        const innerDiv = document.createElement('div');
        innerDiv.className = 'inner';

        // Create the head div
        const headDiv = document.createElement('div');
        headDiv.className = 'head';

        // Create a div to wrap the buttons
        const buttonWrapper = document.createElement('div');
        buttonWrapper.className = 'button-wrapper';

        // Create buttons for daily, monthly, and weekly
        const dailyButton = document.createElement('button');
        dailyButton.innerText = 'Daily';
        dailyButton.className = 'daily-button';
        dailyButton.setAttribute('data-payload', payload.payload);
        dailyButton.setAttribute('data-action', 'dailyGraph');

        const monthlyButton = document.createElement('button');
        monthlyButton.innerText = 'Monthly';
        monthlyButton.className = 'monthly-button';
        monthlyButton.setAttribute('data-payload', payload.payload);
        monthlyButton.setAttribute('data-action', 'monthlyGraph');

        const weeklyButton = document.createElement('button');
        weeklyButton.innerText = 'Weekly';
        weeklyButton.className = 'weekly-button';
        weeklyButton.setAttribute('data-payload', payload.payload);
        weeklyButton.setAttribute('data-action', 'weeklyGraph');

        // Append buttons to button wrapper
        buttonWrapper.appendChild(dailyButton);
        buttonWrapper.appendChild(weeklyButton);
        buttonWrapper.appendChild(monthlyButton);

        // Create a space for nav
        const navSpace = document.createElement('div');
        navSpace.className = 'nav-space';
        navSpace.innerHTML = navHtml;

        // Create the close button
        const closeButton = document.createElement('button');
        closeButton.innerText = 'Close';
        closeButton.className = 'close-button';
        closeButton.onclick = () => {
            document.body.removeChild(lightboxOverlay);
        };

        // Create the main div
        const mainDiv = document.createElement('div');
        mainDiv.className = 'main';

        // Create the body div
        const bodyDiv = document.createElement('div');
        bodyDiv.className = 'body';

        // Create the canvas for the chart
        const canvas = document.createElement('div');
        canvas.id = 'fism-fonchart';

        // Append button wrapper and nav space to head div
        headDiv.appendChild(buttonWrapper);
        headDiv.appendChild(navSpace);
        headDiv.appendChild(closeButton);

        // Append main div and canvas to inner div
        innerDiv.appendChild(mainDiv);
        innerDiv.appendChild(canvas);

        // Append head div and inner div to outer div
        outerDiv.appendChild(headDiv);
        outerDiv.appendChild(innerDiv);
        outerDiv.appendChild(bodyDiv);

        // Append the outer div to the lightbox overlay
        lightboxOverlay.appendChild(outerDiv);

        // Append the lightbox overlay to the body
        document.body.appendChild(lightboxOverlay);

        const canvasCht = document.getElementById('fism-fonchart');


        // Get the width and height of the canvas element
        const width = canvasCht.offsetWidth;
        const height = canvasCht.offsetHeight;

        // Create a new chart instance
        const chart = createChart(canvasCht, {
            width: width,
            height: height,
            timeframe: '1D',
            layout: { textColor: 'black', background: { type: 'solid', color: '#FFFAF3' } },
            localization: {
                locale: 'en-US'
            }
        });

        chart.applyOptions({
            rightPriceScale: {
                scaleMargins: {
                    top: 0.4, // leave some space for the legend
                    bottom: 0.15,
                },
            },
            crosshair: {
                // hide the horizontal crosshair line
                horzLine: {
                    visible: false,
                    labelVisible: false,
                },
            },
            // hide the grid lines
            grid: {
                vertLines: {
                    visible: true,
                },
                horzLines: {
                    visible: true,
                },
            },
        });

        const candlestickSeries = chart.addCandlestickSeries({
            upColor: '#1A1A1A', downColor: '#EA9A31', borderVisible: false,
            wickUpColor: '#1A1A1A', wickDownColor: '#EA9A31'
        });

        candlestickSeries.setData(dataPoints);

        const symbolName = payload.payload;

        const container = document.getElementById('fism-fonchart');

        const legend = document.createElement('div');
        legend.style = `position: absolute; left: 12px; top: 12px; z-index: 1; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 800;background: #fff;padding: 20px; border: 1px solid #000;`;
        legend.style.color = 'black';
        container.appendChild(legend);

        const getLastBar = series => {
            const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
            return series.dataByIndex(lastIndex);
        };
        const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
        const setTooltipHtml = (name, date, price) => {
            legend.innerHTML = `<div style="font-size: 24px; margin: 4px 0px;">${name}</div><div style="font-size: 22px; margin: 4px 0px;">${price}</div><div>${date}</div>`;
        };

        const updateLegend = param => {
            const validCrosshairPoint = !(
                param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
            );
            const bar = validCrosshairPoint ? param.seriesData.get(candlestickSeries) : getLastBar(candlestickSeries);
            // time is in the same format that you supplied to the setData method,
            // which in this case is YYYY-MM-DD
            const time = bar.time;
            const price = bar.value !== undefined ? bar.value : bar.close;
            const formattedPrice = formatPrice(price);
            setTooltipHtml(symbolName, time, formattedPrice);
        };

        chart.subscribeCrosshairMove(updateLegend);

        updateLegend(undefined);

        chart.timeScale().fitContent();

    }

    createFismGsLightboxLoader() {
        // Create the lightbox div
        const lightbox = document.createElement('div');
        lightbox.id = 'fism_gs_lightbox';

        // Create the loader div
        const loader = document.createElement('div');
        loader.className = 'fism_gs_loader';

        // Add the SVG to the loader div
        loader.innerHTML = `
            <svg width="105" height="105" viewBox="0 0 105 105" xmlns="http://www.w3.org/2000/svg" fill="#fff">
                <circle cx="12.5" cy="12.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="0s" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="12.5" cy="52.5" r="12.5" fill-opacity=".5">
                    <animate attributeName="fill-opacity" begin="100ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="52.5" cy="12.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="300ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="52.5" cy="52.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="600ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="92.5" cy="12.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="800ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="92.5" cy="52.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="400ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="12.5" cy="92.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="700ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="52.5" cy="92.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="500ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
                <circle cx="92.5" cy="92.5" r="12.5">
                    <animate attributeName="fill-opacity" begin="200ms" dur="1s" values="1;.2;1" calcMode="linear" repeatCount="indefinite" />
                </circle>
            </svg>
            `;

        // Append the loader to the lightbox
        lightbox.appendChild(loader);

        // Append the lightbox to the body
        document.body.appendChild(lightbox);
    }

    createCustomLegend(chart) {

        const container = chart.renderTo;

        const existingLegend = container.nextElementSibling;
        if (existingLegend && existingLegend.className === 'custom-legend') {
            existingLegend.remove();
        }

        const legendContainer = document.createElement('div');
        legendContainer.className = 'custom-legend';
        legendContainer.style.textAlign = 'center';
        legendContainer.style.marginTop = '20px';


        chart.series[0].points.forEach((point) => {
            const legendItem = document.createElement('div');
            legendItem.style.display = 'inline-block';
            legendItem.style.marginRight = '20px';
            legendItem.innerHTML = `
            <span style="display:inline-block;width:10px;height:10px;background-color:${point.color};margin-right:5px;"></span>
            ${point.name}`;
            legendContainer.appendChild(legendItem);
        });


        container.parentNode.insertBefore(legendContainer, container.nextSibling);
    }

    checkAndRemoveFismGsLoader() {
        const lightbox = document.getElementById('fism_gs_lightbox');
        if (lightbox) {
            lightbox.parentNode.removeChild(lightbox);
        }
    }

}
