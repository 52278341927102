import '../css/style.css';
import { ProcessAjax } from './modules/ajax.js';
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-fixedheader-dt';
import Highcharts from 'highcharts';
import managePortfolio from './modules/portfolio.js';
import { SelectWidget } from './modules/multiselect.js';



const prAjax = new ProcessAjax(Highcharts);

document.addEventListener('click', function (event) {
    const action = event.target.dataset.action;
    let payload = event.target.dataset.payload;
    const loader = event.target.dataset.loader;

    if (action) {
        if (loader === "true") {
            prAjax.process(action, payload, true);
        } else {
            prAjax.process(action, payload);
        }
    }
});

const assetSelectElement = document.getElementById('asset-select');

if (assetSelectElement) {
    assetSelectElement.addEventListener('change', function (event) {
        const action = "selectChange";
        const payload = JSON.stringify({ payload: event.target.value });
        const loader = false;

        prAjax.process(action, payload, loader);
    });
}


(function ($) {

    managePortfolio();

    const tableElement = document.querySelector('#fismDatatable');
    const chartsElement = document.querySelector('#pie-chart-container');
    const MultiSelect = document.querySelector('#select-widgets-container');



    if (chartsElement) {

        function adjustChartHeight() {
            const width = window.innerWidth;
            let height = 500;

            if (width < 768) {
                height = 300;
                chart.legend.update({ enabled: false });
                chart.series[0].update({
                    dataLabels: {
                        enabled: false
                    }
                });
            } else {
                chart.legend.update({ enabled: true });
                chart.series[0].update({
                    dataLabels: {
                        enabled: true
                    }
                });
            }
            if (width < 400) {
                height = 200;
            }

            chart.setSize(null, height);
        }

        const chart = Highcharts.chart(chartsElement, {
            chart: {
                type: 'pie',
                backgroundColor: '#FFFAF3',
                height: 500,
                events: {
                    render: function () {
                        createCustomLegend(this);
                    }
                }
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '<b>{point.y}% of total</b>'
            },
            legend: {
                labelFormatter: function () {
                    return this.name + ': ' + this.y + '%';
                }
            },
            series: [{
                name: 'Sectors',
                colorByPoint: true,
                data: pieData
            }],
            credits: {
                enabled: false
            }
        });

        window.addEventListener('resize', adjustChartHeight);
        adjustChartHeight();

    }

    function createCustomLegend(chart) {

        const container = chart.renderTo;

        const existingLegend = container.nextElementSibling;
        if (existingLegend && existingLegend.className === 'custom-legend') {
            existingLegend.remove();
        }

        const legendContainer = document.createElement('div');
        legendContainer.className = 'custom-legend';
        legendContainer.style.textAlign = 'center';
        legendContainer.style.marginTop = '20px';


        chart.series[0].points.forEach((point) => {
            const legendItem = document.createElement('div');
            legendItem.style.display = 'inline-block';
            legendItem.style.marginRight = '20px';
            legendItem.style.fontSize = '12px';
            legendItem.innerHTML = `
            <span style="display:inline-block;width:10px;height:10px;background-color:${point.color};margin-right:5px;"></span>
            ${point.name}`;
            legendContainer.appendChild(legendItem);
        });


        container.parentNode.insertBefore(legendContainer, container.nextSibling);
    }

    if (tableElement) {

        const checkboxes = document.querySelectorAll('.mkt-filter-checkbox-container input[type="checkbox"]');
        const buyCheckbox = document.getElementById('buy');
        const holdCheckbox = document.getElementById('hold');
        const sellCheckbox = document.getElementById('sell');

        const noCapCheckbox = document.getElementById('no-cap');
        const microCapCheckbox = document.getElementById('micro');
        const smallCapCheckbox = document.getElementById('small-cap');
        const mediumCapCheckbox = document.getElementById('medium-cap');
        const largeCapCheckbox = document.getElementById('large-cap');


        let table = new DataTable('#fismDatatable', {
            orderCellsTop: true,
            searchPlaceholder: "Search...",
            lengthMenu: [[50, 100, 250, 500], [50, 100, 250, 500]],
            drawCallback: function (settings) {
                $(this).find('tbody tr').removeClass('odd even');
                $(this).find('tbody tr:odd').addClass('even');
                $(this).find('tbody tr:even').addClass('odd');
            },
            columnDefs: [
                {
                    targets: [13],
                    responsivePriority: 1
                },
                {
                    targets: [7],
                    responsivePriority: 10000,
                    className: 'none'
                },
                {
                    className: 'dtr-control arrow-right',
                    orderable: false,
                    target: -1
                }
            ],
            order: [],
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            }
        });


        $('#dt-search-0').attr('placeholder', 'Search...');

        // Function to build input search boxes and select dropdowns
        function buildSelect(table) {
            table.columns([0, 1, 2, 4, 3, 5]).every(function (colIdx) {
                var column = this;
                var header = $(column.header());
                var filterRow = header.closest('thead').find('tr:eq(1) th').eq(colIdx);

                if (colIdx === 3 || colIdx === 5) {
                    // For the select dropdown in the 3rd column (index 3)
                    var select = filterRow.find('select');

                    // Get unique values from the filtered (visible) column data
                    const uniqueValues = Array.from(new Set(
                        table.rows({ search: 'applied' }).data().toArray().map(row => {
                            const cell = row[colIdx];
                            const tempDiv = document.createElement('div');
                            tempDiv.innerHTML = cell;
                            return $(tempDiv).find('span').text().trim();
                        }).filter(value => value && value.trim() !== '')
                    )).sort();

                    // Clear existing options and add the new options
                    select.empty().append('<option value="">All</option>');
                    uniqueValues.forEach(value => {
                        select.append(`<option value="${value}">${value}</option>`);
                    });

                    // Restore the selected value
                    const currentSearchValue = column.search();
                    select.val(currentSearchValue || '');

                    // Rebind the change event
                    select.off('change').on('change', function () {
                        const selectedValue = $(this).val();
                        column.search(selectedValue).draw();
                        select.val(selectedValue);
                    });

                } else {
                    // For text input filters in columns 0, 1, and 2
                    var input = filterRow.find('input');

                    input.off('keyup change').on('keyup change', function () {
                        if (column.search() !== this.value) {
                            column.search(this.value).draw();
                        }
                    });
                }
            });
        }

        buildSelect(table);
        table.on('draw', function () {

        });



        if (MultiSelect) {
            MultiSelect.innerHTML = `
            <div id="widget1" class="fism-gs-flex-widget-item"></div>
            <div id="widget2" class="fism-gs-flex-widget-item"></div>
            <div id="widget3" class="fism-gs-flex-widget-item"></div>
            <div id="clear-widgets" class="fism-gs-flex-widget-item fism-gs-flex-widget-clear-item"><button id="fism_gs_clear"><svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L11 11M1 11L11 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg></button></div>
            <div id="widget4" class="fism-gs-flex-widget-item fism-gs-widget-right-item"></div>
            `;
            let selarr;

            if (tableElement.getAttribute('data-type') === 'buy') {
                selarr = ['Buy'];
            } else if (tableElement.getAttribute('data-type') === 'broad') {
                selarr = [];
            }


            searchMultipleValues(8, selarr);

            new SelectWidget(document.getElementById('widget1'), 'Market Cap', '1', []);
            new SelectWidget(document.getElementById('widget2'), 'Style', '1', []);
            new SelectWidget(document.getElementById('widget3'), 'Recent', '1');
            new SelectWidget(document.getElementById('widget4'), 'FISM Opinion', '1', selarr);


            // Function to handle changes
            function handleChanges(mutations) {

                mutations.forEach(mutation => {
                    if (mutation.type === 'attributes' && mutation.attributeName === 'data-selected') {
                        const widgetId = mutation.target.id;
                        const newValue = mutation.target.dataset.selected;

                        if (widgetId === 'widget1') {
                            searchMultipleValues(6, JSON.parse(newValue));
                        }

                        if (widgetId === 'widget2') {
                            searchMultipleValues(11, JSON.parse(newValue));
                        }

                        if (widgetId === 'widget3') {
                            searchMultipleValues(12, JSON.parse(newValue));
                        }

                        if (widgetId === 'widget4') {
                            searchMultipleValues(8, JSON.parse(newValue));
                        }
                    }
                });
            }

            // Create a MutationObserver
            const observer = new MutationObserver(handleChanges);

            // Observe all widget containers
            MultiSelect.querySelectorAll('.fism-gs-flex-widget-item').forEach(widget => {
                observer.observe(widget, { attributes: true, attributeFilter: ['data-selected'] });
            });



            function searchMultipleValues(columnIndex, values) {
                var searchString = values.length > 0 ? values.join('|') : null;

                table
                    .column(columnIndex)
                    .search(
                        searchString === null ? '' : "^(" + searchString + ")$",
                        true,   // Use regex
                        false   // Disable smart search
                    )
                    .draw();
            }
        }
    }

})(jQuery);

