import Highcharts from 'highcharts';

export function createChart2(pieData) {
    var chartsElement = document.getElementById('porrfolio-graph-2');

    if (!chartsElement) {
        return;
    }

    return Highcharts.chart(chartsElement, {
        chart: {
            type: 'pie',
            backgroundColor: '#fcf4e9',
            height: 410
        },
        title: {
            text: ''
        },
        tooltip: {
            pointFormat: '<b>{point.y}% of total</b>'
        },
        plotOptions: {
            pie: {
                size: '80%', // Control the size of the pie
                center: ['50%', '50%'], // Center the pie in the available space
                showInLegend: true
            }
        },
        legend: {
            enabled: false  // This explicitly disables the legend
        },
        series: [{
            name: 'Sectors',
            colorByPoint: true,
            data: pieData
        }],
        credits: {
            enabled: false
        }
    });
}