export function addEventListeners(widget) {
    const header = widget.container.querySelector('.fism_gs_select-header');
    const dropdown = widget.container.querySelector('.fism_gs_dropdown');
    const cancelBtn = widget.container.querySelector('.fism_gs_cancel-btn');

    header.addEventListener('click', (e) => {
        e.stopPropagation();
        widget.toggleDropdown();
    });

    dropdown.addEventListener('click', (e) => {
        e.stopPropagation();
    });

    dropdown.addEventListener('change', (e) => {
        if (e.target.type === 'checkbox') {
            widget.toggleOption(e.target.value);

            // Handle widget type behavior
            if (widget.widgetType === '1') {
                widget.clearType2Widgets();
            } else if (widget.widgetType === '2') {
                widget.clearType1Widgets();
            }
        }
    });

    cancelBtn.addEventListener('click', (e) => {
        e.stopPropagation();
        widget.clearSelection();

        // Handle widget type behavior
        if (widget.widgetType === '1') {
            widget.clearType2Widgets();
        } else if (widget.widgetType === '2') {
            widget.clearType1Widgets();
        }
    });

    document.addEventListener('click', (e) => {
        if (widget.isOpen && !widget.container.contains(e.target)) {
            widget.closeDropdown();
        }
    });
}