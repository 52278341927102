export function renderTemplate(title, options, selected) {
    const selectedCount = selected.length;
    return `
        <div class="fism_gs_select-widget">
            <div class="fism_gs_widget-header">
                <h3>${title}</h3>
                <div class="fism_gs_select-header">
                    <span class="fism_gs_selected-count"><span class="fism_gs_count">${selectedCount}</span> selected</span>
                    <svg class="fism_gs_dropdown-arrow" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M2 4L6 8L10 4" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <button class="fism_gs_cancel-btn">
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L11 11M1 11L11 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
            <div class="fism_gs_dropdown" style="display: none;">
                ${options.map(option => `
                    <div class="fism_gs_option ${option.class}">
                        <input type="checkbox" id="${title}-${option.value}" value="${option.value}" ${selected.includes(option.value) ? 'checked' : ''}>
                        <label for="${title}-${option.value}" class="${option.class}">${option.label}</label>
                    </div>
                `).join('')}
            </div>
            <div class="fism_gs_selected-items">
                ${selected.map(value => {
        const option = options.find(opt => opt.value === value);
        return `<span class="fism_gs_selected-item ${option.class}">${option.label}</span>`;
    }).join('')}
            </div>
        </div>
    `;
}