export function dollar_recommendations(pieData, pieData2, totalMarketValue) {
    // Calculate the changes
    const currentAllocations = {};
    pieData.forEach(item => {
        currentAllocations[item.name] = item.y;
    });

    let recommendations = pieData2.map(target => {
        const currentPercent = currentAllocations[target.name] || 0;
        const targetAmount = (target.y / 100) * totalMarketValue;
        const currentAmount = (currentPercent / 100) * totalMarketValue;
        const change = Math.round(targetAmount - currentAmount);

        if (change !== 0) {
            return {
                sector: target.name,
                amount: change
            };
        }
    }).filter(Boolean);

    recommendations.sort((a, b) => Math.abs(b.amount) - Math.abs(a.amount));

    const popup = document.createElement('div');
    popup.id = 'dollar_amounts';
    popup.className = 'recommendation-item-popup';

    popup.innerHTML = `
        <div class="outer">
            <div class="inner">
                <button class="close-button">Close</button>
                ${recommendations.map(rec => `
                    <div style="display: flex; margin: 8px 0;">
                        <div style="width: 50%">${rec.sector}:</div>
                        <div style="width: 50%">$${rec.amount > 0 ? '+' : ''}${rec.amount.toLocaleString()}</div>
                    </div>
                `).join('')}
            </div>
        </div>
    `;

    document.getElementById('dollar-amount-reccomendation').addEventListener('click', () => {
        document.body.appendChild(popup);
    });

    popup.querySelector('.close-button').addEventListener('click', () => {
        popup.remove();
    });
}