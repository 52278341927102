import { widgetOptions } from './widget-options.js';
import { renderTemplate } from './template-renderer.js';
import { addEventListeners } from './event-handlers.js';

export class SelectWidget {
    static instances = [];

    constructor(container, title, widgetType, defaultSelected = []) {
        this.container = container;
        this.title = title;
        this.options = widgetOptions[title];
        this.selected = [...defaultSelected];  // Initialize with provided default selection values
        this.isOpen = false;
        this.widgetType = widgetType;
        this.updateDataAttribute();
        this.render();
        this.addEventListeners();
        SelectWidget.instances.push(this);
    }

    static closeAllExcept(exceptWidget) {
        SelectWidget.instances.forEach(widget => {
            if (widget !== exceptWidget && widget.isOpen) {
                widget.closeDropdown();
            }
        });
    }

    static clearWidgets(widgetNumbers) {
        SelectWidget.instances.forEach((widget, index) => {
            if (widgetNumbers.includes(index + 1)) {
                widget.clearSelection();
            }
        });
    }

    render() {
        this.container.innerHTML = renderTemplate(this.title, this.options, this.selected);
    }

    addEventListeners() {
        addEventListeners(this);
    }

    toggleDropdown() {
        if (!this.isOpen) {
            SelectWidget.closeAllExcept(this);
        }
        this.isOpen = !this.isOpen;
        const dropdown = this.container.querySelector('.fism_gs_dropdown');
        const widget = this.container.querySelector('.fism_gs_select-widget');
        dropdown.style.display = this.isOpen ? 'block' : 'none';
        widget.classList.toggle('open', this.isOpen);
    }

    closeDropdown() {
        if (this.isOpen) {
            this.isOpen = false;
            const dropdown = this.container.querySelector('.fism_gs_dropdown');
            const widget = this.container.querySelector('.fism_gs_select-widget');
            dropdown.style.display = 'none';
            widget.classList.remove('open');
        }
    }

    toggleOption(option) {
        const index = this.selected.indexOf(option);
        if (index > -1) {
            this.selected.splice(index, 1);
        } else {
            this.selected.push(option);
        }
        this.updateUI();
        this.updateDataAttribute();

        // Handle widget type behavior
        if (this.widgetType === '1') {
            this.clearType2Widgets();
        } else if (this.widgetType === '2') {
            this.clearType1Widgets();
        }
    }

    clearType1Widgets() {
        SelectWidget.instances.forEach(widget => {
            if (widget.widgetType === '1' && widget !== this) {
                widget.clearSelection();
            }
        });
    }

    clearType2Widgets() {
        SelectWidget.instances.forEach(widget => {
            if (widget.widgetType === '2') {
                widget.clearSelection();
            }
        });
    }

    clearSelection() {
        this.selected = [];
        this.updateUI();
    }

    updateUI() {
        const selectedCount = this.container.querySelector('.fism_gs_count');
        selectedCount.textContent = this.selected.length;

        const selectedItems = this.container.querySelector('.fism_gs_selected-items');
        selectedItems.innerHTML = this.selected.map(value => {
            const option = this.options.find(opt => opt.value === value);
            return `<span class="fism_gs_selected-item ${option.class}">${option.label}</span>`;
        }).join('');

        const checkboxes = this.container.querySelectorAll('.fism_gs_option input[type="checkbox"]');
        checkboxes.forEach(checkbox => {
            checkbox.checked = this.selected.includes(checkbox.value);
        });

        this.updateDataAttribute();
    }

    updateDataAttribute() {
        this.container.dataset.selected = JSON.stringify(this.selected);
    }

    static checkForChanges() {
        SelectWidget.instances.forEach(widget => {
            const currentSelection = JSON.parse(widget.container.dataset.selected || '[]');
            if (JSON.stringify(currentSelection) !== JSON.stringify(widget.selected)) {
                widget.selected = currentSelection;
                widget.updateUI();
                // You can add a custom event dispatch here if needed
                widget.container.dispatchEvent(new CustomEvent('selectionChanged', { detail: widget.selected }));
            }
        });
    }
}

// Add this outside of the class definition
document.addEventListener('DOMContentLoaded', () => {
    const clearButton = document.getElementById('fism_gs_clear');
    if (clearButton) {
        clearButton.addEventListener('click', function () {
            SelectWidget.clearWidgets([1, 2, 3]);
            console.log('Widgets 1, 2, and 3 cleared');
        });
    }
});