import { displayPopup, handlePortfolioPopup, handlePortfolioInputPopup } from './popupManager.js';
import { handleTable } from './tableManager.js';
import { DateTime } from 'luxon';
import { createChart } from 'lightweight-charts';



function addPortfolioItem(buttonId) {


    const sortedSectors = jsonSectorData.sectors.sort((a, b) => a.localeCompare(b));

    const sectorOptions = sortedSectors.map(sector =>
        `<option value="${sector}">${sector}</option>`
    ).join('');

    const sortedindustries = jsonIndustryData.map(name =>
        `<option value="${name}">${name}</option>`
    ).join('');

    const html = `
                    <form id="portfolio-item-form">
                        <input type="hidden" id="portfolio-item-id" name="portfolio-item-id" value="${buttonId}">
                        <label for="portfolio-item-name">Symbol</label>
                        <input type="text" id="portfolio-item-name" name="portfolio-item-name">
                        <label for="portfolio-item-amount">Shares Owned</label>
                        <input type="text" id="portfolio-item-amount" name="portfolio-item-amount">
                        <label for="portfolio-item-price">Style</label>
                        <select id="portfolio-item-style" name="portfolio-item-sector" style="margin-bottom: 20px;">
                            <option value="">Select a Style</option>
                            <option value="Growth">Growth</option>
                            <option value="Income">Income</option>
                            <option value="Growth and Income">Growth and Income</option>
                            <option value="Aggresive">Aggressive</option>
                        </select>
                        <label for="portfolio-item-price" style="display: none">Average Cost/Share</label>
                        <input type="text" id="portfolio-item-price" name="portfolio-item-price" style="display: none" value="1">
                        <label for="portfolio-item-sector">Sector</label>
                        <select id="portfolio-item-sector" name="portfolio-item-sector">
                        <option value="0">Select a Sector</option>
                        ${sectorOptions}
                        <option value="Other" >Other</option>
                        </select>
                        <label for="portfolio-item-price">Industry</label>
                        <select id="portfolio-item-industry" name="portfolio-item-industry" style="margin-bottom: 20px;">
                           <option value="">Select an Industry</option>
                            ${sortedindustries}
                        </select>
                        <button type="submit">Submit</button>
                    </form>`;

    displayPopup(html, 'add_portfolio_popup', 'recommendation-item-popup');

}


export default function managePortfolio() {

    const delegates = [];

    const delegateEvent = (selector, handler) => {
        // Instead of adding a listener, store the selector and handler
        delegates.push({ selector, handler });
    };


    const portSelect = document.getElementById('portfolio-select');

    if (portSelect) {
        portSelect.addEventListener('change', (event) => {
            const confirmed = confirm("Changing the portfolio will update the sections. Do you want to proceed?");

            if (confirmed) {
                const newPortfolioValue = event.target.value;
                // Get the selected option's text
                const selectedOption = event.target.options[event.target.selectedIndex];
                const portfolioText = selectedOption.text;

                const ajaxUrl = fism_google.ajax_url;
                const nonce = fism_google.nonce;
                const portID = portfolioID;
                // Add portfolioText to the URL
                const url = `${ajaxUrl}?action=updatePortfolioname&nonce=${nonce}&portfolio=${newPortfolioValue}&portfolioText=${encodeURIComponent(portfolioText)}&portfolioID=${portID}`;

                // Make the Ajax call
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === 200) {
                            // Show success message and reload
                            alert('Portfolio successfully updated. The page will reload when you close this message.');
                            window.location.reload();
                        } else {
                            alert('Error updating portfolio: ' + (data.message || 'Unknown error'));
                            // Revert the select back to its previous value
                            event.target.value = event.target.dataset.previousValue;
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        alert('Error updating portfolio. Please try again.');
                        // Revert the select back to its previous value
                        event.target.value = event.target.dataset.previousValue;
                    });
            } else {
                // Revert the select back to its previous value
                event.target.value = event.target.dataset.previousValue;
            }
        });
    }

    // Single document click listener
    document.addEventListener('click', (event) => {
        const path = event.composedPath();

        // Check each delegate against the event
        delegates.forEach(({ selector, handler }) => {
            const target = path.find(element => {
                if (!(element instanceof Element)) return false;

                if (selector.startsWith('.')) {
                    return element.classList.contains(selector.slice(1));
                } else if (selector.startsWith('[data-')) {
                    const matches = selector.match(/\[([^\]=]+)(=["']([^"']+)["'])?\]/);
                    if (!matches) return false;
                    const [_, attrName, __, attrValue] = matches;

                    // Check both attribute presence and value
                    if (attrValue) {
                        return element.getAttribute(attrName) === attrValue;
                    }
                    return element.hasAttribute(attrName);
                } else if (selector.startsWith('#')) {
                    return element.id === selector.slice(1);
                } else {
                    return element.tagName.toLowerCase() === selector.toLowerCase();
                }
            });

            if (target) {
                handler.call(target, event);
            }
        });
    });


    document.addEventListener("DOMContentLoaded", function () {


        delegateEvent('[data-portfolioctl="update"]', function (event) {
            event.preventDefault();

            // Check if data-id attribute exists
            const itemId = this.getAttribute('data-id');
            const item = this.getAttribute('data-item');

            const row = this.closest('tr');

            // Get the specified columns (0, 1, 6, 9)
            const selectedData = {
                symbol: row.cells[0].textContent.trim(),
                shares: row.cells[1].textContent.trim(),
                category: row.cells[5].textContent.trim(),
                style: row.cells[7].textContent.trim(),
                industry: row.cells[6].textContent.trim(),
            };

            if (!itemId) {
                console.error('No data-id attribute found on element');
                return;
            }

            const sortedSectors = jsonSectorData.sectors.sort((a, b) => a.localeCompare(b));
            const sectorOptions = sortedSectors.map(sector =>
                `<option value="${sector}" ${sector === selectedData.category ? 'selected' : ''}>${sector}</option>`
            ).join('');

            const sortedindustries = jsonIndustryData.map(name =>
                `<option value="${name}" ${name === selectedData.industry ? 'selected' : ''}>${name}</option>`
            ).join('');

            // Create popup HTML with readonly symbol field
            const popupHTML = `
        <div id="add_portfolio_popup_update" class="recommendation-item-popup" style="display: block;">
            <div class="outer">
                <div class="inner">
                    <button class="close-button">Close</button>
                    
                    <form id="portfolio-item-form">
                        <input type="hidden" id="portfolio-item-id" name="portfolio-item-id" value="${itemId}">
                        <label for="portfolio-item-name">Symbol</label>
                        <input type="text" id="portfolio-item-name" name="portfolio-item-name" value="${selectedData.symbol}" readonly style="background-color: #f0f0f0;">
                        <label for="portfolio-item-amount">Shares Owned</label>
                        <input type="text" id="portfolio-item-amount" name="portfolio-item-amount" value="${selectedData.shares}">
                        <p class="field-error" id="amount-error" style="color: red; display: none;">Please enter a whole number for shares</p>
                        <p class="field-error" id="price-error" style="color: red; display: none;">Please enter a valid number for price</p>
                        <label for="portfolio-item-price">Style</label>
                        <select id="portfolio-item-style" name="portfolio-item-sector" style="margin-bottom: 20px;">
                            <option value="">Select a Style</option>
                            <option value="Growth" ${"Growth" === selectedData.style ? 'selected' : ''}>Growth</option>
                            <option value="Income" ${"Income" === selectedData.style ? 'selected' : ''}>Income</option>
                            <option value="Growth and Income" ${"Growth and Income" === selectedData.style ? 'selected' : ''}>Growth and Income</option>
                            <option value="Aggressive" ${"Aggressive" === selectedData.style ? 'selected' : ''}>Aggressive</option>
                        </select>
                        <label for="portfolio-item-sector">Sector</label>
                        <select id="portfolio-item-sector" name="portfolio-item-sector">
                            <option value="0">Select a Sector</option>
                            ${sectorOptions}
                            <option value="Other" ${"Other" === selectedData.category ? 'selected' : ''} >Other</option>
                        </select>
                        <label for="portfolio-item-price">Industry</label>
                        <select id="portfolio-item-industry" name="portfolio-item-industry" style="margin-bottom: 20px;">
                            <option value="" >Select an Industry</option>
                            ${sortedindustries}
                        </select>
                        <button type="submit">Save Changes</button>
                    </form>
                </div>
            </div>
        </div>
    `;

            document.body.insertAdjacentHTML('beforeend', popupHTML);

            // Get the newly created popup and form elements
            const popup = document.getElementById('add_portfolio_popup_update');
            const form = document.getElementById('portfolio-item-form');
            const amountInput = document.getElementById('portfolio-item-amount');
            const amountError = document.getElementById('amount-error');

            // Add input validation for shares (integers only)
            amountInput.addEventListener('input', function () {
                const isValid = /^\d+$/.test(this.value);
                amountError.style.display = isValid ? 'none' : 'block';
                this.style.borderColor = isValid ? '' : 'red';
            });


            // Add form submit handler with validation
            form.addEventListener('submit', function (e) {
                e.preventDefault();

                // Validate inputs before submission
                const sharesValue = amountInput.value;

                const isSharesValid = /^\d+$/.test(sharesValue);


                // Show error messages if invalid
                amountError.style.display = isSharesValid ? 'none' : 'block';

                // Highlight invalid fields
                amountInput.style.borderColor = isSharesValid ? '' : 'red';

                // Only proceed if all fields are valid
                if (!isSharesValid) {
                    return;
                }

                // Get form values
                const formData = {
                    id: itemId,
                    item: item,
                    symbol: document.getElementById('portfolio-item-name').value,
                    shares: sharesValue,
                    sector: document.getElementById('portfolio-item-sector').value,
                    style: document.getElementById('portfolio-item-style').value,
                    industry: document.getElementById('portfolio-item-industry').value,
                };

                // Construct the URL with form data
                const ajaxUrl = fism_google.ajax_url;
                const nonce = fism_google.nonce;
                const url = `${ajaxUrl}?action=updateRow&nonce=${nonce}&id=${formData.id}&item=${formData.item}&symbol=${formData.symbol}&shares=${formData.shares}&sector=${formData.sector}&style=${formData.style}&industry=${formData.industry}`;

                // Make the Ajax call
                fetch(url)
                    .then(response => response.json())
                    .then(data => {
                        if (data.status === 200) {
                            // Show success message and reload
                            window.location.reload();
                        } else {
                            alert('Error updating item: ' + (data.message || 'Unknown error'));
                        }
                    })
                    .catch(error => {
                        console.error('Error:', error);
                        alert('Error updating item. Please try again.');
                    });
            });

            // Close button handler
            const closeButton = popup.querySelector('.close-button');
            closeButton.addEventListener('click', function () {
                popup.remove();
            });
        });

        delegateEvent('[data-portfolioctl="notes"]', function (event) {
            event.preventDefault();

            // Check if data-id attribute exists
            const itemId = this.getAttribute('data-id');
            const item = this.getAttribute('data-item');

            if (!itemId) {
                console.error('No data-id attribute found on element');
                return;
            }

            // First fetch existing notes
            const ajaxUrl = fism_google.ajax_url;
            const nonce = fism_google.nonce;
            const fetchUrl = `${ajaxUrl}?action=fetchNotes&nonce=${nonce}&id=${itemId}&item=${item}`;

            fetch(fetchUrl)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 200) {
                        const notes = data.notes || ''; // Use empty string if no notes exist

                        // Create popup HTML
                        const popupHTML = `
                    <div id="portfolio_notes_popup" class="recommendation-item-popup" style="display: block;">
                        <div class="outer">
                            <div class="inner">
                                <button class="close-button">Close</button>
                                
                                <form id="portfolio-notes-form">
                                    <input type="hidden" id="portfolio-item-id" name="portfolio-item-id" value="${itemId}">
                                    <input type="hidden" id="portfolio-item-code" name="portfolio-item-code" value="${item}">
                                    
                                    <label for="portfolio-item-notes">Notes</label>
                                    <textarea 
                                        id="portfolio-item-notes" 
                                        name="portfolio-item-notes" 
                                        rows="10" 
                                        style="width: 100%; margin-bottom: 20px;"
                                    >${notes}</textarea>
                                    
                                    <button type="submit">Save Notes</button>
                                </form>
                            </div>
                        </div>
                    </div>
                `;

                        document.body.insertAdjacentHTML('beforeend', popupHTML);

                        // Get the newly created popup and form elements
                        const popup = document.getElementById('portfolio_notes_popup');
                        const form = document.getElementById('portfolio-notes-form');

                        // Add form submit handler
                        form.addEventListener('submit', function (e) {
                            e.preventDefault();

                            // Get form values
                            const formData = {
                                id: itemId,
                                item: item,
                                notes: document.getElementById('portfolio-item-notes').value
                            };

                            // Construct the URL for saving notes
                            const saveUrl = `${ajaxUrl}?action=saveNotes&nonce=${nonce}&id=${formData.id}&item=${formData.item}&notes=${encodeURIComponent(formData.notes)}`;

                            // Make the Ajax call to save notes
                            fetch(saveUrl)
                                .then(response => response.json())
                                .then(data => {
                                    if (data.status === 200) {
                                        alert('Notes successfully saved.');
                                        popup.remove();
                                    } else {
                                        alert('Error saving notes: ' + (data.message || 'Unknown error'));
                                    }
                                })
                                .catch(error => {
                                    console.error('Error:', error);
                                    alert('Error saving notes. Please try again.');
                                });
                        });

                        // Close button handler
                        const closeButton = popup.querySelector('.close-button');
                        closeButton.addEventListener('click', function () {
                            popup.remove();
                        });
                    } else {
                        alert('Error fetching notes: ' + (data.message || 'Unknown error'));
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert('Error fetching notes. Please try again.');
                });
        });


        delegateEvent('[data-portfolioctl="delete"]', function (event) {
            event.preventDefault();

            // Check if data-id attribute exists
            const itemId = this.getAttribute('data-id');
            const item = this.getAttribute('data-item');
            if (!itemId) {
                console.error('No data-id attribute found on element');
                return;
            }

            // Confirm deletion
            if (!confirm('Are you sure you want to delete this item?')) {
                return;
            }

            // Construct the URL
            const ajaxUrl = fism_google.ajax_url;
            const nonce = fism_google.nonce;
            const url = `${ajaxUrl}?action=deleteRow&nonce=${nonce}&id=${itemId}&item=${item}`;

            // Make the Ajax call
            fetch(url)
                .then(response => response.json())
                .then(data => {
                    if (data.status === 200) {
                        // Show success message and reload
                        window.location.reload();
                    } else {
                        alert('Error deleting item: ' + (data.message || 'Unknown error'));
                    }
                })
                .catch(error => {
                    console.error('Error:', error);
                    alert('Error deleting item. Please try again.');
                });
        });

        function buildGraphMetrics(data) {

            if (!data) {
                return '';
            }

            const isValidValue = value => value && value !== 'None' && value !== 'NaN';

            const createDataList = (dataList) => {
                return dataList.filter(item => isValidValue(item.value)).map(item => {
                    return `<li><span class="title">${item.title}</span><span class="value">${item.value}</span></li>`;
                }).join('');
            };

            const companyData = [
                { title: 'Company Name', value: data.Name },
                { title: 'Company Symbol', value: data.Symbol },
                { title: 'Industry', value: data.Industry },
                { title: 'Sector', value: data.Sector },
                { title: 'Address', value: data.Address },
                { title: 'Description', value: data.Description },
                { title: 'Fiscal Year End', value: data.FiscalYearEnd },
                { title: 'Latest Quarter', value: data.LatestQuarter }
            ];

            const priceData = [
                { title: '50 Day Moving Average', value: data['50DayMovingAverage'] },
                { title: '200 Day Moving Average', value: data['200DayMovingAverage'] },
                { title: '52 Week High', value: data['52WeekHigh'] },
                { title: '52 Week Low', value: data['52WeekLow'] },
                { title: 'Analyst Target Price', value: data['AnalystTargetPrice'] }
            ];

            const keyMetrics = [
                { title: 'Diluted EPS (TTM)', value: data['DilutedEPSTTM'] },
                { title: 'EBITDA', value: data['EBITDA'] },
                { title: 'Earnings Per Share', value: data['EPS'] },
                { title: 'EV-to-EBITDA', value: data['EVToEBITDA'] },
                { title: 'Enterprise-Value-to-Revenue', value: data['EVToRevenue'] },
                { title: 'Beta', value: data['Beta'] },
                { title: 'Forward P/E', value: data['ForwardPE'] },
                { title: 'Gross Margin (TTM)', value: data['GrossProfitTTM'] },
                { title: 'Market Capitalization', value: data['MarketCapitalization'] ? `$${parseInt(data['MarketCapitalization']).toLocaleString()}` : null },
                { title: 'Operating Margin (TTM)', value: data['OperatingMarginTTM'] ? `${(data['OperatingMarginTTM'] * 100).toFixed(2)}%` : null },
                { title: 'PEG Ratio', value: data['PEGRatio'] },
                { title: 'P/E ratio', value: data['PERatio'] },
                { title: 'Price-to-Book Ratio', value: data['PriceToBookRatio'] },
                { title: 'Price–sales ratio TTM', value: data['PriceToSalesRatioTTM'] }
            ];

            const dividendData = [
                { title: 'Dividend date', value: data['DividendDate'] },
                { title: 'Ex-dividend date', value: data['ExDividendDate'] },
                { title: 'Dividend per Share', value: data['DividendPerShare'] },
                {
                    title: 'Dividend Yield',
                    value: data['DividendYield'] && !isNaN(parseFloat(data['DividendYield']))
                        ? (parseFloat(data['DividendYield']) * 100).toFixed(2)
                        : ''
                }
            ];

            let htmlOutput = '<ul class="metrics-v2">';

            const companyDataHTML = createDataList(companyData);
            const priceDataHTML = createDataList(priceData);
            const keyMetricsHTML = createDataList(keyMetrics);
            const dividendDataHTML = createDataList(dividendData);

            if (companyDataHTML) {
                htmlOutput += `
                <li>
                    <p>Company Data</p>
                    <div id="companyData" class="dataHolder"><ul>${companyDataHTML}</ul></div>
                </li>`;
            }

            if (priceDataHTML) {
                htmlOutput += `
                <li>
                    <p>Price Data</p>
                    <div id="priceData" class="dataHolder"><ul>${priceDataHTML}</ul></div>
                </li>`;
            }

            if (keyMetricsHTML) {
                htmlOutput += `
                <li>
                    <p>Key Metrics</p>
                    <div id="keyMetrics" class="dataHolder"><ul>${keyMetricsHTML}</ul></div>
                </li>`;
            }

            if (dividendDataHTML) {
                htmlOutput += `
                <li>
                    <p>Dividend Data</p>
                    <div id="dividendData" class="dataHolder"><ul>${dividendDataHTML}</ul></div>
                </li>`;
            }

            htmlOutput += '</ul>';

            return htmlOutput === '<ul class="metrics-v2"></ul>' ? '' : htmlOutput;
        }


        function portcreatePopup(navHtml, streamData, payload) {
            // Check if a popup with the class 'fism-gs' already exists
            if (document.querySelector('.fism-gs')) {
                return; // If it exists, do nothing
            }

            const timeSeries = streamData["Time Series (Daily)"];

            const dataPoints = Object.keys(timeSeries).map(date => {
                const dayData = timeSeries[date];
                const luxonDate = DateTime.fromISO(date); // Parse date using Luxon
                return {
                    time: luxonDate.toFormat('yyyy-MM-dd'), // Format date as desired
                    open: parseFloat(dayData["1. open"]),
                    high: parseFloat(dayData["2. high"]),
                    low: parseFloat(dayData["3. low"]),
                    close: parseFloat(dayData["4. close"])
                };
            }).reverse();

            // Create the lightbox overlay
            const lightboxOverlay = document.createElement('div');
            lightboxOverlay.className = 'lightbox-overlay';

            // Create the outer div for the popup
            const outerDiv = document.createElement('div');
            outerDiv.className = 'fism-gs';

            // Create the inner div
            const innerDiv = document.createElement('div');
            innerDiv.className = 'inner';

            // Create the head div
            const headDiv = document.createElement('div');
            headDiv.className = 'head';

            // Create a div to wrap the buttons
            const buttonWrapper = document.createElement('div');
            buttonWrapper.className = 'button-wrapper';

            // Create buttons for daily, monthly, and weekly
            const dailyButton = document.createElement('button');
            dailyButton.innerText = 'Daily';
            dailyButton.className = 'daily-button';
            dailyButton.setAttribute('data-payload', payload);
            dailyButton.setAttribute('data-action', 'dailyGraph');

            const monthlyButton = document.createElement('button');
            monthlyButton.innerText = 'Monthly';
            monthlyButton.className = 'monthly-button';
            monthlyButton.setAttribute('data-payload', payload);
            monthlyButton.setAttribute('data-action', 'monthlyGraph');

            const weeklyButton = document.createElement('button');
            weeklyButton.innerText = 'Weekly';
            weeklyButton.className = 'weekly-button';
            weeklyButton.setAttribute('data-payload', payload);
            weeklyButton.setAttribute('data-action', 'weeklyGraph');

            // Append buttons to button wrapper
            buttonWrapper.appendChild(dailyButton);
            buttonWrapper.appendChild(weeklyButton);
            buttonWrapper.appendChild(monthlyButton);

            // Create a space for nav
            const navSpace = document.createElement('div');
            navSpace.className = 'nav-space';
            navSpace.innerHTML = navHtml;

            // Create the close button
            const closeButton = document.createElement('button');
            closeButton.innerText = 'Close';
            closeButton.className = 'close-button';
            closeButton.onclick = () => {
                document.body.removeChild(lightboxOverlay);
            };

            // Create the main div
            const mainDiv = document.createElement('div');
            mainDiv.className = 'main';

            // Create the body div
            const bodyDiv = document.createElement('div');
            bodyDiv.className = 'body';

            // Create the canvas for the chart
            const canvas = document.createElement('div');
            canvas.id = 'fism-fonchart';

            // Append button wrapper and nav space to head div
            headDiv.appendChild(buttonWrapper);
            headDiv.appendChild(navSpace);
            headDiv.appendChild(closeButton);

            // Append main div and canvas to inner div
            innerDiv.appendChild(mainDiv);
            innerDiv.appendChild(canvas);

            // Append head div and inner div to outer div
            outerDiv.appendChild(headDiv);
            outerDiv.appendChild(innerDiv);
            outerDiv.appendChild(bodyDiv);

            // Append the outer div to the lightbox overlay
            lightboxOverlay.appendChild(outerDiv);

            // Append the lightbox overlay to the body
            document.body.appendChild(lightboxOverlay);

            const canvasCht = document.getElementById('fism-fonchart');


            // Get the width and height of the canvas element
            const width = canvasCht.offsetWidth;
            const height = canvasCht.offsetHeight;

            // Create a new chart instance
            const chart = createChart(canvasCht, {
                width: width,
                height: height,
                timeframe: '1D',
                layout: { textColor: 'black', background: { type: 'solid', color: '#FFFAF3' } },
                localization: {
                    locale: 'en-US'
                }
            });

            chart.applyOptions({
                rightPriceScale: {
                    scaleMargins: {
                        top: 0.4, // leave some space for the legend
                        bottom: 0.15,
                    },
                },
                crosshair: {
                    // hide the horizontal crosshair line
                    horzLine: {
                        visible: false,
                        labelVisible: false,
                    },
                },
                // hide the grid lines
                grid: {
                    vertLines: {
                        visible: true,
                    },
                    horzLines: {
                        visible: true,
                    },
                },
            });

            const candlestickSeries = chart.addCandlestickSeries({
                upColor: '#1A1A1A', downColor: '#EA9A31', borderVisible: false,
                wickUpColor: '#1A1A1A', wickDownColor: '#EA9A31'
            });

            candlestickSeries.setData(dataPoints);

            const symbolName = payload;

            const container = document.getElementById('fism-fonchart');

            const legend = document.createElement('div');
            legend.style = `position: absolute; left: 12px; top: 12px; z-index: 1; font-size: 14px; font-family: sans-serif; line-height: 18px; font-weight: 800;background: #fff;padding: 20px; border: 1px solid #000;`;
            legend.style.color = 'black';
            container.appendChild(legend);

            const getLastBar = series => {
                const lastIndex = series.dataByIndex(Number.MAX_SAFE_INTEGER, -1);
                return series.dataByIndex(lastIndex);
            };
            const formatPrice = price => (Math.round(price * 100) / 100).toFixed(2);
            const setTooltipHtml = (name, date, price) => {
                legend.innerHTML = `<div style="font-size: 24px; margin: 4px 0px;">${name}</div><div style="font-size: 22px; margin: 4px 0px;">${price}</div><div>${date}</div>`;
            };

            const updateLegend = param => {
                const validCrosshairPoint = !(
                    param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0
                );
                const bar = validCrosshairPoint ? param.seriesData.get(candlestickSeries) : getLastBar(candlestickSeries);
                // time is in the same format that you supplied to the setData method,
                // which in this case is YYYY-MM-DD
                const time = bar.time;
                const price = bar.value !== undefined ? bar.value : bar.close;
                const formattedPrice = formatPrice(price);
                setTooltipHtml(symbolName, time, formattedPrice);
            };

            chart.subscribeCrosshairMove(updateLegend);

            updateLegend(undefined);

            chart.timeScale().fitContent();

        }

        delegateEvent('[data-portfolioctl="graph"]', function (event) {
            event.preventDefault();

            // Check if data-id attribute exists
            const itemId = this.getAttribute('data-id');
            if (!itemId) {
                console.error('No data-id attribute found on element');
                return;
            }


            // Construct the URL
            const ajaxUrl = fism_google.ajax_url;
            const nonce = fism_google.nonce;
            const url = `${ajaxUrl}?action=fetchGraphData&nonce=${nonce}&payload=${itemId}`;

            // Make the Ajax call
            fetch(url)
                .then(response => response.json())
                .then(response => {


                    let metricsData = buildGraphMetrics(response.data.summary);
                    let streamData = response.data.stream;


                    portcreatePopup(metricsData, streamData, itemId);

                    return;


                });
        });

        const portfolioCancel = document.querySelectorAll('.fism-port-item-cancel-button');

        if (portfolioCancel.length > 0) {
            portfolioCancel.forEach(button => {
                button.addEventListener('click', function (event) {
                    event.preventDefault();

                    const portfolioId = this.getAttribute('data-id');

                    if (confirm(`Warning: You are about to cancel this portfolio. All data associated with this portfolio will be permanently deleted. Do you want to proceed with cancellation?`)) {
                        // User confirmed, proceed with deletion
                        console.log(`Deleting portfolio ${portfolioId}`);

                        const ajaxUrl = fism_google.ajax_url;
                        const nonce = fism_google.nonce;
                        const url = `${ajaxUrl}?action=deleteMainPortfolio&nonce=${nonce}&payload=${portfolioId}`;

                        fetch(url)
                            .then(response => response.json())
                            .then(response => {

                                if (response.status === 200) {
                                    // Get the base URL without query parameters
                                    const baseUrl = window.location.protocol + '//' +
                                        window.location.host +
                                        window.location.pathname;

                                    // Redirect to the clean URL
                                    window.location.href = baseUrl;
                                } else {
                                    console.error('Failed to delete portfolio:', response);
                                }
                            })
                            .catch(error => {
                                console.error('Error deleting portfolio:', error);
                            });
                    } else {
                        // User cancelled, do nothing
                        console.log('Deletion cancelled');
                    }
                });
            });
        }


        // Select all elements with the class
        const portfolioElements = document.querySelectorAll('.fism_google_add_portfolio');

        // Add click event listener to each element
        portfolioElements.forEach(portfolioElement => {
            portfolioElement.addEventListener("click", function () {
                // Get the data-count attribute value from the clicked element
                const countAttribute = this.getAttribute('data-count');

                // Convert to integer, default to 0 if attribute is null or undefined
                const count = countAttribute ? parseInt(countAttribute, 10) : 0;

                // Set alternate to true if count is 0, false otherwise
                const alternate = count === 0;

                const label = alternate ? "Create your first portfolio:" : "Add Portfolio:";

                // Create the allocation model options
                const modelOptions = portfolioSelect
                    .map(model => `<option value="${model.value}">${model.text}</option>`)
                    .join('');

                const html = `
            <form id="portfolio-form">
                <label for="portfolio-name">${label}</label>
                <input type="text" id="portfolio-name" name="portfolio-name">
                
                <label for="allocation-model">Select Allocation Model:</label>
                <select id="allocation-model" name="allocation-model">
                    <option value="">Please select a model</option>
                    ${modelOptions}
                </select>
                
                <button type="submit">Submit</button>
            </form>`;

                displayPopup(html, 'add_portfolio_popup', 'portfolio-popup', !alternate);
            });
        });

        const addPortfolioButtons = document.querySelectorAll('#portfolio-add-item, #portfolio-add-item-bottom');

        if (addPortfolioButtons.length > 0) {
            addPortfolioButtons.forEach(button => {
                button.addEventListener("click", function () {
                    const buttonId = this.getAttribute('data-id');
                    addPortfolioItem(buttonId);
                });
            });
        }

        handleTable();

    });

}