// popupManager.js




// Function to create popup template
function createPopup(html, id, className, showCloseButton = true) {
    const closeButtonHTML = showCloseButton ? '<button class="close-button">Close</button>' : '';
    const popupHTML = `
        <div id="${id}" class="${className}" style="display:none;">
            <div class="outer">
                <div class="inner">
                    ${closeButtonHTML}
                    ${html}
                </div>
            </div>
        </div>
    `;
    return popupHTML;
}




function handlePortfolioPopup(portfolioName, modelId) {
    const ajaxUrl = fism_google.ajax_url;
    const nonce = fism_google.nonce;
    const action = 'addPortfolio';

    // Create a structured data object
    const portfolioData = {
        name: portfolioName,
        modelId: modelId
    };

    jQuery.ajax({
        url: ajaxUrl,
        type: 'POST',
        data: {
            action: action,
            nonce: nonce,
            portfolioData: portfolioData
        },
        success: function (response) {
            console.log('Success:', response);
            location.reload();
        },
        error: function (xhr, status, error) {
            console.log('Error:', error);
            // Show user-friendly error message
            alert('Failed to create portfolio. Please try again.');
        }
    });
}

function handlePortfolioInputPopup(id, symbol, amount, price, sector, style, industry) {
    const ajaxUrl = fism_google.ajax_url;
    const nonce = fism_google.nonce;
    const action = 'addPortfolioItem';

    jQuery.ajax({
        url: ajaxUrl,
        type: 'POST',
        data: {
            action: action,
            nonce: nonce,
            portfolioItemId: id,
            symbol: symbol,
            amount: amount,
            price: price,
            sector: sector,
            style: style,
            industry: industry
        },
        success: function (response) {
            console.log('Success:', response);
            // Handle the response from the server
            // For example, you might want to update the UI or show a success message
            location.reload();
        },
        error: function (xhr, status, error) {
            console.log('Error:', error);
            // Handle any errors
            alert('Error adding portfolio item. Please try again.');
        }
    });
}

// Function to handle form submission
async function handleSubmit(e, form, popupElement) {
    console.log('Handling form submission...');
    e.preventDefault();

    const formId = form.getAttribute('id');

    if (formId === 'portfolio-form') {
        // Handle the existing portfolio form
        const input = form.querySelector('input[type="text"]');
        const select = form.querySelector('#allocation-model');
        const inputValue = input.value.trim();
        const selectedModel = select.value;

        if (inputValue === '') {
            console.log('Input value is empty, displaying error alert...');
            alert('Please enter a portfolio name');
            return;
        }

        if (selectedModel === '') {
            console.log('No allocation model selected, displaying error alert...');
            alert('Please select an allocation model');
            return;
        }

        handlePortfolioPopup(inputValue, selectedModel);
        popupElement.style.display = 'none';

    } else if (formId === 'portfolio-item-form') {
        const symbolInput = form.querySelector('#portfolio-item-name');
        const amountInput = form.querySelector('#portfolio-item-amount');
        const priceInput = form.querySelector('#portfolio-item-price');
        const sectorInput = form.querySelector('#portfolio-item-sector');
        const styleInput = form.querySelector('#portfolio-item-style');
        const industryInput = form.querySelector('#portfolio-item-industry');
        const hiddenIdInput = form.querySelector('#portfolio-item-id');

        const symbol = symbolInput.value.trim();
        const amount = amountInput.value.trim();
        const price = priceInput.value.trim();
        const sector = sectorInput.value;
        const style = styleInput.value;
        const industry = industryInput.value;
        const id = hiddenIdInput.value;

        // Reset all error states before validation
        const resetValidation = () => {
            // Remove all error messages
            const existingErrors = form.querySelectorAll('.error');
            existingErrors.forEach(error => error.remove());

            // Remove all input error classes
            const errorInputs = form.querySelectorAll('.input-error');
            errorInputs.forEach(input => input.classList.remove('input-error'));

            // Remove all validated classes
            const validatedInputs = form.querySelectorAll('.validated');
            validatedInputs.forEach(input => input.classList.remove('validated'));

            // Remove symbol matches if they exist
            const symbolMatches = form.querySelector('#symbol-matches');
            if (symbolMatches) symbolMatches.remove();

            // Remove the fetchselect class from the form
            if (form) {
                form.classList.remove('fetchselect');
            }

            // Show all form fields that might have been hidden
            const formFields = form.querySelectorAll('.form-field');
            formFields.forEach(field => {
                if (field) {
                    field.style.display = '';
                }
            });

            // Show submit button if it was hidden
            const submitBtn = form.querySelector('button[type="submit"]');
            if (submitBtn) submitBtn.style.display = '';

            // Reset form state
            if (form) {
                form.classList.remove('formerror');
                form.classList.remove('success');
            }
        };

        // Reset validation state
        resetValidation();

        let hasError = false;
        let symbolValidationComplete = false;

        // Validation function to handle form state
        const checkFormValidity = () => {
            // Only proceed if symbol validation is complete
            if (!symbolValidationComplete) return;

            // Validate amount (must be an integer)
            if (amount === '') {
                const errorP = document.createElement('p');
                errorP.className = 'error';
                errorP.textContent = 'This field cannot be empty';
                amountInput.parentNode.insertBefore(errorP, amountInput.nextSibling);
                amountInput.classList.add('input-error');
                amountInput.classList.remove('validated');
                hasError = true;
            } else if (!Number.isInteger(Number(amount)) || Number(amount) <= 0) {
                const errorP = document.createElement('p');
                errorP.className = 'error';
                errorP.textContent = 'Please enter a valid positive whole number';
                amountInput.parentNode.insertBefore(errorP, amountInput.nextSibling);
                amountInput.classList.add('input-error');
                amountInput.classList.remove('validated');
                hasError = true;
            } else {
                // Add validated class for properly filled amount field
                amountInput.classList.add('validated');
            }

            // Validate price (must be a number/float)
            if (price === '') {
                const errorP = document.createElement('p');
                errorP.className = 'error';
                errorP.textContent = 'This field cannot be empty';
                priceInput.parentNode.insertBefore(errorP, priceInput.nextSibling);
                priceInput.classList.add('input-error');
                priceInput.classList.remove('validated');
                hasError = true;
            } else if (isNaN(Number(price)) || Number(price) <= 0) {
                const errorP = document.createElement('p');
                errorP.className = 'error';
                errorP.textContent = 'Please enter a valid positive number';
                priceInput.parentNode.insertBefore(errorP, priceInput.nextSibling);
                priceInput.classList.add('input-error');
                priceInput.classList.remove('validated');
                hasError = true;
            } else {
                // Add validated class for properly filled price field
                priceInput.classList.add('validated');
            }

            // Validate sector
            if (sector === '0') {
                const errorP = document.createElement('p');
                errorP.className = 'error';
                errorP.textContent = 'You need to select a valid sector';
                sectorInput.parentNode.insertBefore(errorP, sectorInput.nextSibling);
                sectorInput.classList.add('input-error');
                sectorInput.classList.remove('validated');
                hasError = true;
            } else {
                // Add validated class for properly selected sector
                sectorInput.classList.add('validated');
            }

            // Add form error class if any errors
            if (hasError) {
                if (form) {
                    form.classList.add('formerror');
                }
                return;
            }

            // If all validations pass
            if (form) {
                form.classList.add('success');
            }
            console.log('All input values are filled and valid, submitting form...');
            handlePortfolioInputPopup(id, symbol, amount, price, sector, style, industry);
            popupElement.style.display = 'none';
        };

        // Validate symbol
        if (symbol === '') {
            const errorP = document.createElement('p');
            errorP.className = 'error';
            errorP.textContent = 'This field cannot be empty';
            symbolInput.parentNode.insertBefore(errorP, symbolInput.nextSibling);
            symbolInput.classList.add('input-error');
            symbolInput.classList.remove('validated');
            hasError = true;
            symbolValidationComplete = true;
            checkFormValidity();
        } else {
            // Add symbol field class first (before AJAX call)
            const symbolFieldContainer = symbolInput.closest('.form-field') || symbolInput.parentNode;
            if (symbolFieldContainer) {
                symbolFieldContainer.classList.add('symbol-field');
            }

            // Symbol lookup logic
            const apiKey = 'QTA3JEUWVOLCEJEF'; // Note: Consider moving this to server-side for security
            const url = `https://www.alphavantage.co/query?function=SYMBOL_SEARCH&keywords=${symbol}&apikey=${apiKey}`;

            // Show loading indicator
            const loadingIndicator = document.createElement('p');
            loadingIndicator.id = 'symbol-loading';
            loadingIndicator.textContent = 'Searching for symbol...';
            symbolInput.parentNode.insertBefore(loadingIndicator, symbolInput.nextSibling);

            fetch(url)
                .then(response => {
                    if (!response.ok) {
                        throw new Error(`Network response was not ok: ${response.status}`);
                    }
                    return response.json();
                })
                .then(data => {
                    // Remove loading indicator
                    const loading = document.getElementById('symbol-loading');
                    if (loading) loading.remove();

                    console.log('API response:', data); // Debug: log the API response

                    if (data.bestMatches && data.bestMatches.length > 0) {
                        // Look for an exact match
                        const exactMatch = data.bestMatches.find(match =>
                            match['1. symbol'].toUpperCase() === symbol.toUpperCase()
                        );

                        if (exactMatch) {
                            // Exact match found, proceed with form
                            console.log('Exact match found:', exactMatch['1. symbol']);
                            symbolInput.classList.add('validated');
                            symbolValidationComplete = true;
                            checkFormValidity();
                        } else {
                            // No exact match, hide form fields and show symbol selection interface

                            // Add fetchselect class to the form
                            if (form) {
                                form.classList.add('fetchselect');
                            }

                            // Get current symbol field container
                            const symbolFieldContainer = symbolInput.closest('.form-field') || symbolInput.parentNode;

                            // Hide all form inputs and error messages except the symbol field
                            const allFormFields = form.querySelectorAll('.form-field');
                            allFormFields.forEach(field => {
                                if (field && field !== symbolFieldContainer) {
                                    field.style.display = 'none';
                                }
                            });

                            // Hide submit button
                            const submitBtn = form.querySelector('button[type="submit"]');
                            if (submitBtn) submitBtn.style.display = 'none';

                            // Create symbol selection interface
                            const matchesContainer = document.createElement('div');
                            matchesContainer.id = 'symbol-matches';
                            matchesContainer.className = 'symbol-selection-panel';
                            matchesContainer.innerHTML = '<h3>We couldn\'t find an exact match</h3>' +
                                '<p>Please select one of the available symbols below to continue:</p>';

                            const matchesList = document.createElement('ul');
                            matchesList.className = 'symbol-options';
                            data.bestMatches.forEach(match => {
                                const matchItem = document.createElement('li');
                                matchItem.className = 'symbol-option';
                                matchItem.innerHTML = `
                                <strong>${match['1. symbol']}</strong> - 
                                ${match['2. name']} 
                                (${match['4. region']}, ${match['8. currency']})
                            `;
                                matchItem.addEventListener('click', () => {
                                    // Set the selected value
                                    symbolInput.value = match['1. symbol'];
                                    symbolInput.classList.add('validated');

                                    // Remove the selection panel
                                    matchesContainer.remove();

                                    // Show all form fields again
                                    const allFormFields = form.querySelectorAll('.form-field');
                                    allFormFields.forEach(field => {
                                        if (field) {
                                            field.style.display = '';
                                        }
                                    });

                                    // Show submit button
                                    if (submitBtn) submitBtn.style.display = '';

                                    // Remove fetchselect class from the form
                                    if (form) {
                                        form.classList.remove('fetchselect');
                                    }

                                    // Just mark symbol as valid, but don't auto-submit
                                    symbolValidationComplete = true;

                                    // Don't automatically check full form validity
                                    // This will prevent auto-submission
                                });
                                matchesList.appendChild(matchItem);
                            });

                            matchesContainer.appendChild(matchesList);

                            // Insert the selection panel after the symbol input
                            symbolInput.parentNode.insertBefore(matchesContainer, symbolInput.nextSibling);

                            // Prevent form submission until a symbol is selected
                            hasError = true;
                            symbolValidationComplete = false; // Don't complete validation until symbol selected
                        }
                    } else {
                        // No matches found
                        const errorP = document.createElement('p');
                        errorP.className = 'error';
                        errorP.textContent = 'No matching symbol found';
                        symbolInput.parentNode.insertBefore(errorP, symbolInput.nextSibling);
                        symbolInput.classList.add('input-error');
                        symbolInput.classList.remove('validated');
                        hasError = true;
                        symbolValidationComplete = true;
                        checkFormValidity();
                    }
                })
                .catch(error => {
                    console.error('Error fetching symbol information:', error);

                    // Remove loading indicator if it exists
                    const loading = document.getElementById('symbol-loading');
                    if (loading) loading.remove();

                    // Always complete validation even on error
                    symbolValidationComplete = true;

                    // Check if we have an API limit error
                    if (error.message && error.message.includes('API call frequency')) {
                        // Handle API limit error specifically
                        const errorP = document.createElement('p');
                        errorP.className = 'error';
                        errorP.textContent = 'API limit reached. Please try again in a minute.';
                        symbolInput.parentNode.insertBefore(errorP, symbolInput.nextSibling);
                        symbolInput.classList.add('input-error');
                        symbolInput.classList.remove('validated');
                        hasError = true;
                        checkFormValidity();
                    }
                });
        }
    } else {
        console.log('Unknown form type');
    }
}

// Function to display popup
function displayPopup(html, id, className, showCloseButton = true) {
    // Remove any existing popup with the same id
    const existingPopup = document.querySelector(`#${id}`);
    if (existingPopup) {
        existingPopup.remove();
    }

    // Create and add the new popup
    const popupHTML = createPopup(html, id, className, showCloseButton);
    document.body.insertAdjacentHTML('beforeend', popupHTML);

    // Get the new popup element
    const popupElement = document.querySelector(`#${id}`);
    popupElement.style.display = 'block';

    if (showCloseButton) {
        // Add event listener to close button
        const closeButton = popupElement.querySelector('.close-button');
        closeButton.addEventListener("click", function () {
            popupElement.style.display = 'none';
        });
    }

    // Add event listener to form submission
    const form = popupElement.querySelector('form');
    form.addEventListener("submit", function (e) {
        handleSubmit(e, this, popupElement);
    });
}

// Export the functions
export { displayPopup, handleSubmit, createPopup };