// tableManager.js
import { displayPopup, handlePortfolioPopup, handlePortfolioInputPopup } from './popupManager.js';
import DataTable from 'datatables.net-dt';
import 'datatables.net-responsive-dt';
import 'datatables.net-buttons-dt';
import 'datatables.net-buttons/js/buttons.print';
import 'datatables.net-rowreorder';
import 'datatables.net-rowreorder-dt';
import 'datatables.net-fixedheader-dt';
import { createChart1 } from './chart1.js';
import { createChart2 } from './chart2.js';
import { createChart3 } from './chart3.js';
import { dollar_recommendations } from './dollarRecommendations.js';


function handleTable() {
    jQuery(document).ready(function ($) {

        function addPortfolioItem(buttonId) {


            const sortedSectors = jsonSectorData.sectors.sort((a, b) => a.localeCompare(b));

            const sectorOptions = sortedSectors.map(sector =>
                `<option value="${sector}">${sector}</option>`
            ).join('');

            const sortedindustries = jsonIndustryData.map(name =>
                `<option value="${name}">${name}</option>`
            ).join('');

            const html = `
                    <form id="portfolio-item-form">
                        <input type="hidden" id="portfolio-item-id" name="portfolio-item-id" value="${buttonId}">
                        <label for="portfolio-item-name">Symbol</label>
                        <input type="text" id="portfolio-item-name" name="portfolio-item-name">
                        <label for="portfolio-item-amount">Shares Owned</label>
                        <input type="text" id="portfolio-item-amount" name="portfolio-item-amount">
                        <label for="portfolio-item-price" style="display: none">Average Cost/Share</label>
                        <input type="text" id="portfolio-item-price" style="display: none" name="portfolio-item-price" value="1">
                        <label for="portfolio-item-price">Style</label>
                        <select id="portfolio-item-style" name="portfolio-item-sector">
                            <option value="">Select a Style</option>
                            <option value="Growth">Growth</option>
                            <option value="Income">Income</option>
                            <option value="Growth and Income">Growth and Income</option>
                            <option value="Aggresive">Aggressive</option>
                        </select>
                        <label for="portfolio-item-sector">Sector</label>
                        <select id="portfolio-item-sector" name="portfolio-item-sector">
                        <option value="0">Select a Sector</option>
                        ${sectorOptions}
                        <option value="Other">Other</option>
                        </select>
                        <label for="portfolio-item-price">Industry</label>
                        <select id="portfolio-item-industry" name="portfolio-item-industry">
                            <option value="">Select an Industry</option>
                            ${sortedindustries}
                        </select>
                        <button type="submit">Submit</button>
                    </form>`;

            displayPopup(html, 'add_portfolio_popup', 'recommendation-item-popup');

        }


        function sortToMatchCategories(sourceData, targetCategories) {

            const totalSourceValue = sourceData.reduce((sum, item) => sum + item.y, 0);

            // Create a new array based on the target categories structure, omitting 0 values initially
            const result = targetCategories.map(category => {

                const matchingCategory = sourceData.find(item => item.name === category.name);


                return {
                    name: category.name,
                    y: matchingCategory ? matchingCategory.y : 0
                };
            });

            // Find the "Other" category
            const otherIndex = result.findIndex(item => item.name === "Other");


            const categorizedSum = result.reduce((sum, item, index) => {
                return index === otherIndex ? sum : sum + item.y;
            }, 0);

            // Calculate what should go to "Other" (all unmatched values)
            const otherValue = totalSourceValue - categorizedSum;

            // Update the "Other" category
            if (otherIndex >= 0) {
                result[otherIndex].y = otherValue;
            } else {

                result.push({ name: "Other", y: otherValue });
            }

            // Remove any categories with 0 values
            const filteredResult = result.filter(item => item.y > 0);

            return filteredResult;
        }

        const MAX_PORTFOLIO_ROWS = 10000;

        let table = new DataTable('#portfolio-table', {
            orderCellsTop: true,
            fixedHeader: {
                header: true,
                headerOffset: 110  // This shifts the header down 50px from the top
            },
            stateSave: false,
            lengthMenu: [[25, 30, 35, 40, 45, 50], [25, 30, 35, 40, 45, 50]],
            drawCallback: function (settings) {
                $(this).find('tbody tr').removeClass('odd even');
                $(this).find('tbody tr:odd').addClass('even');
                $(this).find('tbody tr:even').addClass('odd');
            },
            dom: '<"d-flex justify-content-between align-items-center flex-top"l<"d-flex"f<"ms-2"B>>>rtip',
            buttons: [
                {
                    extend: 'print',
                    text: 'Print',
                    className: 'btn-print',
                    exportOptions: {
                        columns: [0, 2, 3, 4, 5, 7, 8, 9, 10, 11]
                    }
                },
                {
                    text: '+ Add Item',
                    className: 'portfolio-add-item',
                    attr: {
                        id: 'portfolio-add-item'
                    },
                    action: function (e, dt, node, config) {

                        const currentRowCount = dt.rows().count();

                        if (currentRowCount >= MAX_PORTFOLIO_ROWS) {
                            // You can replace this alert with your preferred notification system
                            alert(`Maximum limit of ${MAX_PORTFOLIO_ROWS} items reached. Cannot add more items. Please add a new portfolio`);
                            return;
                        }

                        addPortfolioItem(portfolioID);
                    }
                }
            ],
            columnDefs: [
                { orderable: true, className: 'reorder', targets: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] },
                { orderable: false, targets: '_all' },
                { visible: false, targets: [1, 7] },
                {
                    className: 'dtr-control arrow-right',
                    orderable: false,
                    target: -1
                },
                {
                    responsivePriority: 1,
                    targets: [11, 12, 13, 14, 15]  // These columns will always show
                },
                {
                    responsivePriority: 2,
                    targets: '_all'    // All other columns have lower priority
                }
            ],
            language: {
                search: "_INPUT_",
                searchPlaceholder: "Search..."
            },
            responsive: {
                details: {
                    type: 'column',
                    target: -1
                }
            },
            footerCallback: function (row, data, start, end, display) {
                var api = this.api();

                // Helper functions
                var parseValue = function (value) {
                    return typeof value === 'string' ?
                        parseFloat(value.replace(/[\$,]/g, '')) :
                        typeof value === 'number' ?
                            value : 0;
                };

                var parsePercentage = function (value) {
                    if (typeof value === 'string') {
                        return parseFloat(value.replace('%', ''));
                    }
                    return typeof value === 'number' ? value : 0;
                };

                // Calculate total market value
                var totalMarketValue = api
                    .column(3, { page: 'all' })
                    .data()
                    .reduce(function (a, b) {
                        return parseValue(a) + parseValue(b);
                    }, 0);

                var weightedDividendSum = 0;
                var dividendValueSum = 0;
                var totalAnnualIncome = 0;
                var rows = api.rows({ page: 'all' }).data();
                var sectorTotals = {};
                var pieData = [];

                rows.each(function (row) {
                    var marketValue = parseValue(row[3]);
                    var dividendYield = parsePercentage(row[10]);
                    var sector = row[6];

                    sectorTotals[sector] = (sectorTotals[sector] || 0) + marketValue;

                    var weight = marketValue / totalMarketValue;

                    if (dividendYield > 0) {
                        weightedDividendSum += marketValue * dividendYield;
                        dividendValueSum += marketValue;

                        // Calculate estimated annual income
                        var positionIncome = marketValue * (dividendYield / 100);
                        totalAnnualIncome += positionIncome;
                    }
                });

                for (var sector in sectorTotals) {
                    pieData.push({
                        name: sector,
                        y: Number(((sectorTotals[sector] / totalMarketValue) * 100).toFixed(1))
                    });
                }


                createChart1(sortToMatchCategories(pieData, pieData2));
                createChart2(pieData2);
                createChart3(pieData2, pieData);

                // Format outputs
                var formattedTotal = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(totalMarketValue);


                dollar_recommendations(pieData, pieData2, totalMarketValue);


                var portfolioDividendYield = dividendValueSum > 0 ?
                    (weightedDividendSum / dividendValueSum).toFixed(2) + '%' :
                    'N/A';

                var formattedIncome = new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                }).format(totalAnnualIncome);

                // Update displays with correct IDs
                $('#market-value-total').html(formattedTotal);
                $('#div-yield').html(portfolioDividendYield);
                $('#port-income').html(formattedIncome); // Updated ID here
            }
        });

        table.on('row-reorder', function (e, diff, edit) {
            let allRows = table.rows().nodes();
            let newOrder = Array.from(allRows).map(row => ({
                code: $(row).find('td:first').data('code'),
                position: $(row).index()
            })).sort((a, b) => a.position - b.position);

            let ajaxUrl = fism_google.ajax_url;
            let nonce = fism_google.nonce;

            let portfolioTable = document.getElementById('portfolio-table');
            let tableID = portfolioTable.getAttribute('data-id');

            fetch(ajaxUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: new URLSearchParams({
                    action: 'portfolioSort',
                    nonce: nonce,
                    items: JSON.stringify(newOrder),
                    tableID: tableID
                })
            });
        });


        function buildPortfolioFilters(table) {



            // Configure filters for Symbol (col 0) and Sector (col 7)
            table.columns([0, 6]).every(function (colIdx) {
                var column = this;
                var header = $(column.header());
                var filterRow = header.closest('thead').find('tr:eq(1) th').eq(colIdx - 1);


                if (colIdx === 6) {  // Sector column - dropdown select
                    var select = filterRow.find('select');

                    // Get unique values from the filtered (visible) column data
                    const uniqueValues = Array.from(new Set(
                        table.rows({ search: 'applied' }).data().toArray()
                            .map(row => row[colIdx])
                            .filter(Boolean) // Remove empty values
                            .sort() // Sort alphabetically
                    ));

                    // Clear existing options and add the new options
                    select.empty().append('<option value="">All Sectors</option>');
                    uniqueValues.forEach(value => {
                        select.append(`<option value="${value}">${value}</option>`);
                    });


                    // Restore the selected value if exists
                    const currentSearchValue = column.search();
                    select.val(currentSearchValue || '');

                    // Bind the change event
                    select.off('change').on('change', function () {
                        const selectedValue = $(this).val();
                        column.search(selectedValue).draw();
                        select.val(selectedValue);
                    });

                } else {  // Symbol column - text input
                    var input = filterRow.find('input');

                    // Restore any existing search value
                    input.val(column.search());

                    // Bind the search event
                    input.off('keyup change').on('keyup change', function () {
                        if (column.search() !== this.value) {
                            column.search(this.value).draw();
                        }
                    });
                }
            });
        }

        // Function to update select options when table is filtered
        function updateSectorSelect(table) {
            const sectorColumn = table.column(6);
            const filterRow = $(sectorColumn.header()).closest('thead').find('tr:eq(1) th').eq(7);
            const select = filterRow.find('select');

            // Store current selection
            const selectedValue = select.val();

            // Get unique values from currently visible rows
            const uniqueValues = Array.from(new Set(
                table.rows({ search: 'applied' }).data().toArray()
                    .map(row => row[7])
                    .filter(Boolean)
                    .sort()
            ));

            // Rebuild select options
            select.empty().append('<option value="">All Sectors</option>');
            uniqueValues.forEach(value => {
                select.append(`<option value="${value}">${value}</option>`);
            });

            // Restore selection
            select.val(selectedValue);
        }

        buildPortfolioFilters(table);

        // Update sector select when table is filtered/drawn
        table.on('draw', function () {
            updateSectorSelect(table);
        });

    });
}

export { handleTable };